body#tinymce {
  margin: 12px !important;
}

#tinymce {
  height: auto;
  background: #fff;

  h1 {
    color: #333;
  }
}