/* ------------------------------------------------------------ *\
	Variables
\* ------------------------------------------------------------ */
/* Usage: @media @mobile {} */
/* ------------------------------------------------------------ *\
	Menu
\* ------------------------------------------------------------ */

#menu-main-menu {
  a {
    padding: 0 5px;
    font-family: 'Open Sans Condensed', sans-serif;
    font-weight: 300;
    font-size: 20px;
    color: #000;
    letter-spacing: 0.12em;
    text-transform: uppercase;
  }
}
/* ------------------------------------------------------------ *\
	Sprite
\* ------------------------------------------------------------ */
body.home-page-main {
  background-color: #fff;
}
/* ------------------------------------------------------------ *\
	Wrapper
\* ------------------------------------------------------------ */
.home-page-main #wrapper {
  padding-top: 139px;
}
.home-page-main #wrapper:after {
  display: none;
}
//------------------------------------------------------------ */
//	Container
// ------------------------------------------------------------ */
@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300|Open+Sans:300,400');

.home-page-main .container {
  height: 1px;
}
@media (max-width: 1023px)  {
  .home-page-main .container {
    height: auto;
  }
}
@media (max-width: 767px)  {
  .home-page-main .container {
    padding: 0;
  }
}
/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */
.home-page-main #header {
  z-index: 20;
  padding: 42px 3.8% 42px 5.6%;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.home-page-main #header #nav {
  margin: -3px -10px 0 0;
}
@media (max-width: 767px)  {
  .home-page-main #header {
    padding: 15px 10px;
  }
}
/* ------------------------------------------------------------ *\
	Main
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Sidebar
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Content
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */
.home-page-main .footer-frame {
  max-width: 960px;
  padding: 57px 10px;
  margin: 0 auto;
}
.home-page-main .footer-frame p {
  font-family: special_eliteregular, serif;
  font-size: 16px;
}
.home-page-main .list-holder {
  margin: -17px 3px 0 0 !important;
}
.home-page-main .footer-holder .city-image--secondary {
  height: 157px;
  background: url(../images/temp/city-image-secondary@2x.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media (max-width: 767px)  {
  .home-page-main .list-holder {
    margin-bottom: 20px !important;
  }
}
/* ------------------------------------------------------------ *\
	Button
\* ------------------------------------------------------------ */
.home-page-main .btn {
  display: inline-block;
  vertical-align: middle;
  width: auto;
  min-width: 149px;
  background-color: transparent !important;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-transition: -webkit-box-shadow 0.4s;
  transition: -webkit-box-shadow 0.4s;
  -o-transition: box-shadow 0.4s;
  transition: box-shadow 0.4s;
  transition: box-shadow 0.4s, -webkit-box-shadow 0.4s;
}
.home-page-main .btn:hover {
  -webkit-box-shadow: inset 0px 0px 0px 3px #ffffff;
  box-shadow: inset 0px 0px 0px 3px #ffffff;
}
.home-page-main .btn--block {
  display: block;
  padding-left: 0;
  padding-right: 0;
}
.home-page-main .btn--lg {
  min-width: 227px;
  height: 53px;
  padding: 0;
  border-width: 2px;
  line-height: 49px;
  text-transform: none;
}
.home-page-main .btn--primary {
  min-width: 227px;
  height: 53px;
  padding: 0;
  border: 0;
  border-radius: 2px;
  background-color: #76AFAC !important;
  line-height: 53px;
  -webkit-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
.home-page-main .btn--primary:hover {
  background-color: #599995 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}
/* ------------------------------------------------------------ *\
	Form Elements
\* ------------------------------------------------------------ */
input::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
}
input:-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}
input::-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}
input::placeholder {
  color: inherit;
  opacity: 1;
}
textarea::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
}
textarea:-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}
textarea::-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}
textarea::placeholder {
  color: inherit;
  opacity: 1;
}
input:-webkit-autofill {
  -webkit-text-fill-color: inherit;
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
}
/* ------------------------------------------------------------ *\
	Form
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	List
\* ------------------------------------------------------------ */
[class^="list-"] {
  list-style: none outside none;
}
/* ------------------------------------------------------------ *\
	Logo
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Nav
\* ------------------------------------------------------------ */
.home-page-main #nav li {
  padding: 0 25px;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 300;
  font-size: 20px;
  color: #000;
  letter-spacing: 0.12em;
  text-transform: uppercase;
}
.home-page-main #nav li a {
  color: inherit;
  -webkit-transition: color 0.4s;
  -o-transition: color 0.4s;
  transition: color 0.4s;
}
.home-page-main #nav li a:hover {
  color: #f26e1f;
}
.home-page-main #nav li.active a {
  font-weight: 300;
}
@media (max-width: 1023px)  {
  .home-page-main #nav {
    width: 100%;
    overflow-x: hidden;
  }
  .home-page-main #nav li {
    padding: 0;
  }
  .home-page-main #nav li a:hover {
    color: #fff;
  }
}
/* ------------------------------------------------------------ *\
	Section
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Slider
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Table
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Widgets
\* ------------------------------------------------------------ */
.widgets {
  list-style: none outside none;
}
/* ------------------------------------------------------------ *\
	Widget
\* ------------------------------------------------------------ */
.home-page-main .intro {
  position: relative;
  padding: 45px 0 0;
  background-color: #fff;
  color: #000;
  text-align: center;
}
.home-page-main .intro .intro__title {
  margin-bottom: 0;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 300;
  font-size: 40px;
  color: inherit;
  line-height: 1.4;
}
.home-page-main .intro--bottom-clipping-arrow:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  width: 100%;
  padding-top: 4.66%;
  margin-top: -3px;
  background-image: url(../images/intro-bottom-clipping-arrow@2x.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  pointer-events: none;
}
.home-page-main .intro--bottom-clipping-triangle {
  margin-bottom: 4.17%;
}
.home-page-main .intro--bottom-clipping-triangle:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  width: 100%;
  padding-top: calc(4.17% + 1px);
  margin-top: 9px;
  background-image: url(../images/intro-bottom-clipping-triangle@2x.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  pointer-events: none;
}
@media (max-width: 767px)  {
  .home-page-main .intro .intro__title {
    font-size: 25px;
  }
  .home-page-main .intro--bottom-clipping-arrow:after {
    padding-top: 15%;
  }
}
.home-page-main .full-height {
  height: 100%;
}
@media (max-width: 1023px)  {
  .home-page-main .full-height {
    height: auto;
  }
}
.home-page-main .background--primary {
  background-color: #76AFAC !important;
}
.home-page-main .background--secondary {
  background-color: #F09A34 !important;
}
.home-page-main .explore-services {
  max-width: 500px;
  padding: 107px 0;
  margin: 0 auto;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 300;
  font-size: 20px;
  color: #fff;
  line-height: 1.3;
  text-align: center;
}
.home-page-main .explore-services h1,
.home-page-main .explore-services h2,
.home-page-main .explore-services h3,
.home-page-main .explore-services h4,
.home-page-main .explore-services h5,
.home-page-main .explore-services h6 {
  font-family: special_eliteregular, serif;
  margin-bottom: 38px;
  color: #fff;
}
.home-page-main .explore-services h1 {
  font-size: 30px;
}
.home-page-main .explore-services p {
  margin-bottom: 39px;
}
.home-page-main .find-block {
  float: none;
  width: auto;
  max-width: 349px;
  padding: 87px 0;
}
.home-page-main .find-block .h1,
.home-page-main .find-block h2,
.home-page-main .find-block h3,
.home-page-main .find-block h4,
.home-page-main .find-block h5,
.home-page-main .find-block h6 {
  margin-bottom: 25px;
  text-transform: none;
}
.home-page-main .find-block .h1 {
  font-size: 30px;
}
@media (max-width: 1023px)  {
  .home-page-main .find-block {
    padding-bottom: 400px;
    margin: 0 auto;
  }
}
@media (max-width: 767px)  {
  .home-page-main .find-block {
    padding-left: 20px;
    padding-right: 20px;
  }
}
/* ------------------------------------------------------------ *\
	Article
\* ------------------------------------------------------------ */
.home-page-main .article {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 18px;
  color: #fff;
  line-height: 1.35;
}
.home-page-main .article .article__head {
  padding: 45px 0 50px;
  background-color: #000;
  text-align: center;
}
.home-page-main .article .article__title {
  font-family: special_eliteregular, serif;
  font-weight: 400;
  color: #fff;
  text-transform: none;
}
.home-page-main .article .article__subtitle {
  margin-bottom: 36px;
  font-family: special_eliteregular, serif;
  font-weight: 400;
  font-size: 24px;
  color: inherit;
}
.home-page-main .article .article__content p {
  margin-bottom: 0;
}
.home-page-main .article .article__image--alignright {
  position: absolute;
  top: 43.5%;
  right: 5.6%;
  z-index: 10;
}
.home-page-main .article .article__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 960px;
  padding: 0 10px;
  margin: 0 auto;
}
.home-page-main .article .article__inner .article__content {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 460px;
  flex: 0 0 460px;
  max-width: 460px;
}
.home-page-main .article .article__section {
  position: relative;
  padding: 102px 0 149px;
}
.home-page-main .article .article__section--blue {
  padding: 82px 0 58px;
  background-color: #76AFAC;
}
.home-page-main .article .article__section--blue:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  width: 100%;
  padding-top: 4.916%;
  margin-top: -3px;
  background-image: url(../images/article-section-clipping-triangle@2x.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  pointer-events: none;
}
.home-page-main .article .article__section--orange {
  padding: 67px 0 0;
  margin-top: 5.57%;
  background-color: #F09A34;
  -webkit-box-shadow: inset 0px 0px 0px 1px #b1b1b2;
  box-shadow: inset 0px 0px 0px 1px #b1b1b2;
}
.home-page-main .article .article__section--orange:before {
  content: '';
  position: absolute;
  bottom: calc(100% - 2px);
  left: 0;
  z-index: 5;
  width: 100%;
  padding-top: 5.57%;
  background-image: url(../images/article-section-clipping-triangle-2@2x.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  pointer-events: none;
}
.home-page-main .article .article__section--orange .article__image {
  position: relative;
  z-index: 5;
  margin-top: -190px;
  margin-right: 30px;
}
.home-page-main .article .article__section--orange .article__content {
  margin-top: -112px;
}
.home-page-main .article .article__section--orange .article__subtitle {
  margin-bottom: 52px;
}
.home-page-main .article .article__section--white {
  color: #000;
}
@media (max-width: 1200px)  {
  .home-page-main .article .article__image--alignright {
    width: 400px;
  }
}
@media (max-width: 1023px)  {
  .home-page-main .article .article__image--alignright {
    top: 55%;
    width: 300px;
  }
  .home-page-main .article .article__section--orange .article__content {
    margin: -50px 0 20px;
  }
  .home-page-main .article .article__section--orange .article__subtitle {
    margin-bottom: 20px;
  }
  .home-page-main .article .article__section--orange .article__image {
    margin-top: -147px;
  }
}
@media (max-width: 767px)  {
  .home-page-main .article .article__inner {
    display: block;
    max-width: 100%;
    padding: 0 10px;
  }
  .home-page-main .article .article__inner .article__content {
    max-width: 100%;
    margin-bottom: 40px;
  }
  .home-page-main .article .article__image--alignright {
    position: relative;
    top: 0;
    right: 0;
    width: 300px;
    margin: 0 auto -170px;
  }
  .home-page-main .article .article__section {
    padding: 150px 0 100px;
  }
  .home-page-main .article .article__section--blue {
    padding: 82px 0 58px;
  }
  .home-page-main .article .article__section--orange {
    padding: 0 0 20px;
  }
  .home-page-main .article .article__section--orange .article__content {
    margin: 0;
  }
  .home-page-main .article .article__section--orange .article__subtitle {
    margin-bottom: 30px;
  }
  .home-page-main .article .article__section--orange .article__image {
    margin: 0 0 20px;
  }
  .home-page-main .article .article__section--orange .article__image img {
    margin-top: -100px;
  }
}
/* ------------------------------------------------------------ *\
	Callout
\* ------------------------------------------------------------ */
.home-page-main .callout {
  padding: 83px 0 249px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 18px;
  color: #000;
  line-height: 1.4;
  text-align: center;
}
.home-page-main .callout p {
  max-width: 80%;
  margin: 0 auto 50px;
}
.home-page-main .callout .callout__inner {
  max-width: 960px;
  padding: 0 10px;
  margin: 0 auto;
}
.home-page-main .callout .callout__title {
  margin-bottom: 38px;
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 40px;
  font-weight: 300;
  color: inherit;
}
/* ------------------------------------------------------------ *\
	City Image
\* ------------------------------------------------------------ */
.home-page-main .city-image--secondary {
  background-image: url(../images/temp/city-image-secondary@2x.png);
}
/* Table of Contents */

/* 01. Banner                 */
/* 02. Blog main page         */
/* 03. Blog single page       */
/* 04. Blog sear results page */

/* ========================= */
/* 01. Banner                */
/* ========================= */
.banner-home, .banner-box  {
  display: none !important;
}

/* ========================= */
/* 02. Blog main page        */
/* ========================= */
.blog .content-block,
.archive .content-block {
  padding: 30px 0 30px 0;
}
.blog .blog-intro,
.archive .blog-intro {
  max-width: 560px;
  margin: auto;
  padding-bottom: 20px;
}
.blog .content-block .blog-search-bar,
.archive .content-block .blog-search-bar {
  background: #74afad;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  padding: 30px 0;
  margin-bottom: 30px;
}
.bsb-container {
  display: flex;
  margin-left: 0;
  margin-right: 0;
  justify-content: space-between;
}
.bsb-container label {
  color: white;
  font-weight: 100;
}
.bsb-container select option {
  color: black;
}
.bsb-search {
  width: 48%;
}
.bsb-search.bsbs-2 {
  padding-right:0;
}

@media (max-width:992px) {
  .bsb-container {
    flex-direction: column;
  }
  .bsb-search {
    padding-right: 0;
  }
  .bsbs-2 {
    padding-top: 30px;
  }
  .bsbs-2 form.search-form {
    padding-right: 0;
  }
  .bsb-search {
    width: 100%;
  }
}

.articles-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.articles-container article {
  width: 33%;
  padding-bottom: 30px;
}
.acai-image {
  position: relative;
}
.acai-custom-bg {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 56.25%;
}
.acai-date {
  font-weight: bold;
  color: white;
  position: absolute;
  right: 10px;
  bottom: 0px;
  line-height: 0.6;
}
.acai-header {
  padding: 20px;
  background-color: #f26e1f;
}
.acai-header a {
  color: white;
}
.acai-header a:hover {
  text-decoration: underline;
}
.entry-summary {
  padding: 20px;
  background-color: #f5f5f5;
}
@media (max-width:992px) {
  .articles-container article {
    width: 100%;
    padding-right: 0;
  }
}

/* The WP Search Bar */
.bsbs-2 label {
  width: 100%;
}
.bsbs-2 label input.search-field {
  border: 1px solid white;
  border-radius: 0;
  box-shadow: none;
  height: 48px;
  width: 100%;
}
.bsbs-2 input.search-submit {
  display: none;
}
.bsbs-2 .search-form {
  padding-right: 0;
}


/* ========================= */
/* 03. Blog single page      */
/* ========================= */
.single-post .content-block {
  padding-top: 30px;
}
.single-post .content-block header h1 {
  color: #f26e1f;
}
.single-post .content-block header .entry-date {
  color: #666;
  font-weight: bold
}
.blog-author-info {
  display: flex;
  align-items: flex-start;
  margin: 30px 0;
}
.bai-photo {
  align-self: flex-start;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 50%;
  border: solid 1px #333;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.bai-text {
  line-height: .5;
}
.bt-name {
  text-transform: uppercase;
  font-weight: bold;
}
.blog-intro-text {
  font-style: italic;
  font-size: 1.8rem;
  color: #666;
}


/* ============================ */
/* 03. Blog search results page */
/* ============================ */
.search-results #wrapper {
  padding-left: 30px;
  padding-right: 30px;
}
.search-results article {
  margin-bottom: 60px;
}
